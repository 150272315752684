/* eslint-disable jsx-a11y/iframe-has-title */
'use client';
import React from 'react';

function Map() {
  const marquess = ['Map Information']; // Replace with your actual array
  const AllMarquess = Array(10).fill(marquess).flat();
  return (
    <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
      <div className="ml-50 mr-50 mb-50 mt-50">
        <div className="flex justify-content-start">
          <div className="text">
            <h3 className="mb-50 col">
              Map{' '}<span className="main-color">Information</span>
            </h3>
          </div>
        </div>
        <div className="row xlg-marg mb-50">
          <div className="col-lg-4 mb-30">
            <div className="img-full fit-img">
                <img src="/assets/imgs/UI-Maps.png" alt="" />
              </div>
          </div>
          <div className="col-lg-8">
            <div>
              <div className="sec-head mb-50">
                <h6 className="mb-5">DISCIPLINE AND COMPETITION TYPES</h6>
                <p>
                  UIOR 2024 is a foot orienteering discipline. Participants are only allowed to walk, jog, or run during the race. Vehicles, bicycles, roller skates, horses and the like are not allowed and prohibited to be used by participants during the race. 
                </p>
                <br/>
                <p>
                  UIOR 2024 uses the SPRINT INDIVIDUAL format with a single race competition system for WRE and SPRINT RELAY format.
                </p>
                <br/>
                <h6 className="mb-5">MAPS</h6>
                <p>
                  <b>Sprint :</b> Scale 1:4.000, Contour Interval  2,5 meters
                  <br/>
                  <b>Sprint Relay :</b> Scale 1:4.000, Contour Interval 2,5 meters
                </p>
                <br/>
                <h6 className="mb-5">COURSE PLANNING</h6>
                <p>
                  The estimated winning time for all classes in the UIOR is 12-15 minutes. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row xlg-marg">
          <div className="col-lg-12">
            <h6>RACE TERRAIN OVERVIEW</h6>
            <img src="/assets/imgs/UI-MapTerrain.png" alt="" />
          </div>
        </div>
      </div>
      <div className="marq-head mb-50">
        <div className="main-marq xlrg text-u o-hidden">
          <div className="slide-har st1">
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Map;
