import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageMain from './pages/page-main.js';
import Page404 from './pages/page-404.js';
import PageAbout from './pages/page-about.js';
import PageContact from './pages/page-contact.js';
import PageWelcome from './pages/page-welcome.js';
import PageOrganizer from './pages/page-organizer.js';
import PageEntryFee from './pages/page-entry-fee.js';
import PageVenue from './pages/page-venue.js';
import PageTransportation from './pages/page-transportation.js';
import PageProgram from './pages/page-program.js';
import PageClasses from './pages/page-classes.js';
import PageEmbargo from './pages/page-embargo.js';
import PageMap from './pages/page-map.js';
import PageTraining from './pages/page-training.js';
import PageStarting from './pages/page-starting.js';
import PageInformation from './pages/page-information.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PageMain />} />
        <Route path="/welcome" element={<PageWelcome />} />
        <Route path="/about" element={<PageAbout />} />
        <Route path="/contact" element={<PageContact />} />
        <Route path="/organizer" element={<PageOrganizer />} />
        <Route path="/entry-fee" element={<PageEntryFee />} />
        <Route path="/venue" element={<PageVenue />} />
        <Route path="/transportation" element={<PageTransportation />} />
        <Route path="/program" element={<PageProgram />} />
        <Route path="/classes" element={<PageClasses />} />
        <Route path="/embargo" element={<PageEmbargo />} />
        <Route path="/maps" element={<PageMap />} />
        <Route path="/training" element={<PageTraining />} />
        <Route path="/starting" element={<PageStarting />} />
        <Route path="/information" element={<PageInformation />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
