import React, { useEffect } from 'react';

import loadBackgroudImages from '../../common/loadBackgroudImages';
function Header() {
  useEffect(() => {
    loadBackgroudImages();
  }, []);
  return (
    <div className="section-padding-top">
      <div className="flex justify-content-start">
        <div className="text">
          <h3 className="mb-50 ml-50 mt-50 col">
            Organizer <span className="main-color">UIOR</span>
          </h3>
        </div>
      </div>
      <header className="header blog-header pb-0">
        <div
          className="background bg-img"
          data-background="/assets/imgs/UI-Organizer.png"
        ></div>
      </header>
    </div>
  );
}

export default Header;
