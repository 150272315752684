/* eslint-disable jsx-a11y/iframe-has-title */
'use client';
import React from 'react';

function Starting() {
  const marquess = ['Starting List']; // Replace with your actual array
  const AllMarquess = Array(10).fill(marquess).flat();
  return (
    <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
      <div className="ml-50 mr-50 mb-50 mt-50">
        <div className="flex justify-content-start">
          <div className="text">
            <h3 className="mb-50 col">
              Starting{' '}<span className="main-color">List</span>
            </h3>
          </div>
        </div>
        <div className="row xlg-marg mb-50">
          <div className="col-lg-6">
            {/* COL */}
          </div>
          <div className="col-lg-6">
             {/* COL */}
          </div>
        </div>
      </div>
      <div className="marq-head mb-50">
        <div className="main-marq xlrg text-u o-hidden">
          <div className="slide-har st1">
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Starting;
