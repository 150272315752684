/* eslint-disable jsx-a11y/iframe-has-title */
'use client';
import React from 'react';

function Classes() {
  const marquess = ['Competition', 'Classes']; // Replace with your actual array
  const AllMarquess = Array(10).fill(marquess).flat();
  return (
    <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
      <div className="ml-50 mr-50 mb-50 mt-50">
        <div className="flex justify-content-start">
          <div className="text">
            <h3 className="mb-50 col">
              Competition{' '}<span className="main-color">Classes</span>
            </h3>
          </div>
        </div>
        <div className="row lg-marg">
            <div className="col-lg-8 md-mb80">
            <div className="item d-flex align-items-center mb-30">
                <span className="num">01</span>
                <div>
                <span className="sub-title-fee main-color mb-5 fw-700">Sprint</span>
                  <h2>
                    W21 / M21 <span className="fw-200 sub-title-info">{'(WRE)'}</span>
                  </h2>
                </div>
                <div className="ml-auto text-align-end">
                  <span className="sub-title-fee mb-5 fw-700">Elite / WRE (all ages)</span>
                  <br/>
                  <p>Winning Time {' : '}<span className="sub-title-fee main-color mb-5 fw-700">12-15 min</span></p>
                </div>
              </div>
              <div className="item d-flex align-items-center mb-30">
                <span className="num">02</span>
                <div>
                  <span className="sub-title-fee main-color mb-5 fw-700">Sprint</span>
                  <h2>
                    W18 / M18
                  </h2>
                </div>
                <div className="ml-auto text-align-end">
                  <span className="sub-title-fee mb-5 fw-700">has not yet reached the age of 19</span>
                  <br/>
                  <p className="ml-auto">Winning Time {' : '}<span className="sub-title-fee main-color mb-5 fw-700">12-15 min</span></p>
                </div>
              </div>
              <div className="item d-flex align-items-center mb-30">
                <span className="num">03</span>
                <div>
                  <span className="sub-title-fee main-color mb-5 fw-700">Sprint</span>
                  <h2>
                    W20 / M20
                  </h2>
                </div>
                <div>
                </div>
                <div className="ml-auto text-align-end">
                  <span className="sub-title-fee mb-5 fw-700">has not yet reached the age of 21</span>
                  <br/>
                  <p className="ml-auto">Winning Time {' : '}<span className="sub-title-fee main-color mb-5 fw-700">12-15 min</span></p>
                </div>
              </div>
              <div className="item d-flex align-items-center mb-30">
                <span className="num">04</span>
                <div>
                  <span className="sub-title-fee main-color mb-5 fw-700">Sprint</span>
                  <h2>
                    W35 / M35
                  </h2>
                </div>
                <div className="ml-auto text-align-end">
                  <span className="sub-title-fee mb-5 fw-700">35 years of age or older</span>
                  <br/>
                  <p className="ml-auto">Winning Time {' : '}<span className="sub-title-fee main-color mb-5 fw-700">12-15 min</span></p>
                </div>
              </div>
              <div className="item d-flex align-items-center mb-30">
                <span className="num">05</span>
                <div>
                  <h2 className="main-color">
                    Sprint Relay
                  </h2>
                </div>
                <div className="ml-auto text-align-end">
                  <span className="sub-title-fee mb-5 fw-700">2 Male & 2 Female</span>
                  <br/>
                  <p className="ml-auto">Winning Time {' : '}<span className="sub-title-fee main-color mb-5 fw-700">48 - 60 min</span></p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-full fit-img">
                <img src="/assets/imgs/UI-Classes.png" alt="" />
              </div>
            </div>
          </div>
      </div>
      <div className="marq-head mb-50">
        <div className="main-marq xlrg text-u o-hidden">
          <div className="slide-har st1">
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Classes;
