/* eslint-disable jsx-a11y/iframe-has-title */
'use client';
import React from 'react';

function Transportation() {
  const marquess = ['Transportations', 'Accomodations']; // Replace with your actual array
  const AllMarquess = Array(10).fill(marquess).flat();
  return (
    <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
      <div className="ml-50 mr-50 mb-50 mt-50">
      <div className="flex justify-content-start">
          <div className="text">
            <h3 className="mb-50 col">
              Transportations &{' '}<span className="main-color">Accomodations</span>
            </h3>
          </div>
        </div>
        <div className="row xlg-marg mb-50">
          <div className="col-lg-6 mb-30">
            <div className="google-map-venue">
              <iframe src="https://www.google.com/maps/d/u/5/embed?mid=1A-ZapcNomz5yQwDwxAhJExgsXdWPazo&ehbc=2E312F&noprof=1"></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <div className="sec-head">
                <h5 className="main-color mb-15">TRANSPORTATIONS</h5>
                  <p className="mb-25">Universitas Indonesia is easily accessible by public transportation, including KRL (Commuter Line), Transjakarta, and online transportation services (such as cabs, Grab, and Gojek). There is also Bis Kuning (Yellow Bus) service that operates at Universitas Indonesia which provides transportation to the competition venue.</p>

                  <h6 className="mb-5">AIRPLANES</h6>
                  <p>International Soekarno Hatta Airport (Tangerang) 50 km</p>
                  <p className="mb-25">International Halim Perdana Kusuma Airport (East Jakarta) 22 km</p>

                  <h6 className="mb-5">TRAIN</h6>
                  <p>Gambir Station 25 km</p>
                  <p>Pasar Senin Station 25 km</p>
                  <p>Lebak Bulus MRT 16 km</p>
                  <p>Universitas Indonesia KRL Station 1 km</p>
                  <p>Pondok Cina KRL Station 500 Meter</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row xlg-marg">
          <div className="col-lg-6">
            <div>
              <div className="sec-head mb-50">
                <h5 className="main-color mb-25">Accomodations</h5>
                <h6 className="mb-5">The Margo Hotel (1,6 KM)</h6>
                <p>
                  St. Margonda No. 358, Kemiri Muka, Beji District, Depok City, West Java, 16424
                </p>
                <br/>
                <h6 className="mb-5">Wisma Makara UI (2,9 KM)</h6>
                <p>
                  St. Prof. DR. Miriam Budiardjo, RW. 3, Srengseng Sawah, Jagakarsa District, South Jakarta, Daerah Khusus Ibukota Jakarta, 12640
                </p>
                <br/>
                <h6 className="mb-5">Margonda Residence 2 (1,6KM)</h6>
                <p>
                  St. Margonda, Pondok Cina, Beji District, Depok City, West Java, 16424
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-100 fit-img">
              <div className="google-map-venue">
                <iframe src="https://www.google.com/maps/d/u/5/embed?mid=1llmb8bL2e0NTaaXLKzej-GBj6i-hvuE&ehbc=2E312F&noprof=1"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marq-head mb-50">
        <div className="main-marq xlrg text-u o-hidden">
          <div className="slide-har st1">
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Transportation;
