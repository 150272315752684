import React from 'react';

function About() {
  const marquess = ['About UIOR']; // Replace with your actual array
  const AllMarquess = Array(10).fill(marquess).flat();
  return (
    <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
      <div className="ml-50 mr-50 mb-50 mt-50">
        <div className="flex justify-content-end">
          <div className="text">
            <h3 className="mb-50 col">
              About <span className="main-color">UIOR</span>
            </h3>
          </div>
        </div>
        <div className="row lg-marg">
          <div className="col-lg-6 mb-50">
            <div className="img-100 fit-img">
              <img src="/assets/imgs/UI-About.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 md-mb80">
            <div className="row lg-marg align-items-start">
              <div className="text">
                  <p>
                  The Universitas Indonesia Orienteering Race (UIOR) is an orienteering event organized by Mapala UI in collaboration with Batavia Orienteering. UIOR is an international event that has been added to the World Ranking Event (WRE) calendar. Competitors in UIOR will compete on the Universitas Indonesia campus, navigating an urban course designed to be safe, comfortable, and challenging. With routes meticulously crafted to push navigation skills to the limit, UIOR offers an unforgettable adventure where every twist and turn keeps you on your toes.  
                  </p>
                </div>
            </div>
            <div className="row lg-marg align-items-center">
              <div className="flex justify-content-center">
                <div className="img-70 mt-30">
                  <img src="/assets/imgs/MapalaUI-BataviaO.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marq-head mb-50">
        <div className="main-marq xlrg text-u o-hidden">
          <div className="slide-har st1">
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
