'use client';
import React, { useEffect } from 'react';

import loadBackgroudImages from '../../common/loadBackgroudImages';
function Header() {
  useEffect(() => {
    loadBackgroudImages();
  }, []);
  const handleClickRegister = (e) => {
    e.preventDefault();
    window.open('https://bit.ly/uior2024', '_blank', 'noopener,noreferrer');
  };
  return (
    <header
      className="main-header bg-img valign"
      data-background="/assets/imgs/UI-BG.jpg"
      data-overlay-dark="6"
    >
      <div className="container ontop">
          <div className="row">
            <div className="flex justify-content-center">
              <h1 className="main-color">Universitas Indonesia</h1>
            </div>
            <div className="flex justify-content-center">
              <h1>Orienteering Race <span>2024</span></h1>
            </div>
            <div className="contact-button flex justify-content-center mt-30">
              <a
                href="https://bit.ly/uior2024"
                className="butn butn-sm butn-bg main-colorbg radius-5"
                onClick={handleClickRegister}
              >
                <span className="text">REGISTER</span>
              </a>
            </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
