/* eslint-disable jsx-a11y/iframe-has-title */
'use client';
import React from 'react';

function Venue() {
  const marquess = ['Venue', 'Location']; // Replace with your actual array
  const AllMarquess = Array(10).fill(marquess).flat();
  return (
    <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
      <div className="ml-50 mr-50 mb-50 mt-50">
      <div className="flex justify-content-start">
          <div className="text">
            <h3 className="mb-50 col">
              Venue &{' '}<span className="main-color">Location</span>
            </h3>
          </div>
        </div>
        <div className="row xlg-marg mb-50">
          <div className="col-lg-6 mb-30">
            <div className="google-map-venue">
            <iframe
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=UniversityOfIndonesiai&t=&z=11&ie=UTF8&iwloc=&output=embed"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <div className="sec-head">
                <h5 className="main-color mb-15">VENUE</h5>
                <p>
                  The event will be held at Universitas Indonesia, administratively located in Beji District, Depok City. The competition area is a campus environment, which features a variety of large and small buildings as well as accessible, irregular network of paved streets, a grassy parkland and urban forests.
                </p>
                <br/>
                <p>
                  Visibility and Runnability are very good.
                </p>
                <br/>
                <p>
                The competition site is 65–75 meters above sea level (MSL) and has a tropical climate. The average daytime temperature ranges from 28–35°C throughout the year. On the competition days in October 2024, the weather is expected to be hot, with moderate rain in the afternoon and a precipitation rate of around 7%. 
                </p>
                <br/>
                <p>
                The mosquito population is likely to increase in the forested areas during hot conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row xlg-marg">
          <div className="col-lg-6">
            <div>
              <div className="sec-head mb-50">
                <h5 className="main-color mb-25">LOCATION</h5>
                <h6 className="mb-5">Competition Arenas</h6>
                <p>
                  All races will take place at Universitas Indonesia, Depok, West Java.
                </p>
                <br/>
                <h6 className="mb-5">Event Center</h6>
                <p>
                  Balairung Universitas Indonesia 
                  <br/>
                  St. Prof. DR. Mahar Mardjono, Pondok Cina, Beji, Depok, West Java.
                </p>
                <br/>
                <h6 className="mb-5">Date and Time</h6>
                <p>
                  Friday — Saturday 
                  <br/>
                  25th October—26th October, 2024
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-100 fit-img">
              <img src="/assets/imgs/UI-Location.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="marq-head mb-50">
        <div className="main-marq xlrg text-u o-hidden">
          <div className="slide-har st1">
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Venue;
