import React from 'react';

function Footer() {
  const handleClick = (e) => {
    e.preventDefault();
    window.open('https://wa.me/6281297267356', '_blank', 'noopener,noreferrer');
  };
  const handleClickRegister = (e) => {
    e.preventDefault();
    window.open('https://bit.ly/uior2024', '_blank', 'noopener,noreferrer');
  };
  return (
    <footer className="clean-footer crev">
      <div className="container pb-40 pt-40 ontop">
        <div className="row justify-content-between">
          <div className="col-lg-2">
            <div className="logo icon-img-100 md-mb80">
              <img src="/assets/imgs/UIOR-White.png" alt="" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="column md-mb50">
              <h6 className="sub-title mb-30">Contact</h6>
              <h6 className="p-color fw-400">
              <p><b>Sekretariat MAPALA UI</b></p>
              <p>Pusat Kegiatan Mahasiswa Universitas Indonesia, Jl. Prof. Dr. Fuad Hassan, Kukusan, Beji, Depok City, West Java 16425</p>
              </h6>
              <h6 className="mt-30 mb-15">
                <a href="#0">ui.orienteeringrace@gmail.com </a>
              </h6>
              <a href="#0" className="underline">
                <span className="fz-22 main-color">
                  <a href="https://wa.me/6281297267356" onClick={handleClick}>+62 812-9726-7356</a>
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="column md-mb50">
              <h6 className="sub-title mb-30">Useful Links</h6>
              <ul className="rest fz-14 opacity-7">
                <li className="mb-15">
                  <a href="/welcome">Welcome</a>
                </li>
                <li className="mb-15">
                  <a href="/about">About Us</a>
                </li>
                <li className="mb-15">
                  <a href="/organizer">Organizer</a>
                </li>
                <li className="mb-15">
                  <a href="/entry-fee">Entry Fee</a>
                </li>
                <li className="mb-15">
                  <a href="https://bit.ly/uior2024" onClick={handleClickRegister}>Register</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="column subscribe-minimal">
              <h6 className="sub-title mb-30">Newsletter</h6>
              <div className="form-group mb-40">
                <input type="text" name="subscrib" placeholder="Your Email" />
                <button>
                  <span className="ti-location-arrow"></span>
                </button>
              </div>
              <ul className="rest social-icon d-flex align-items-center">
                <li className="hover-this cursor-pointer">
                  <a href="https://facebook.com/MapalaUniversitasIndonesia" className="hover-anim">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="hover-this cursor-pointer ml-10">
                  <a href="https://www.instagram.com/mapala_ui" className="hover-anim">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="pt-30 pb-30 mt-80 bord-thin-top">
          <div className="text-center">
            <p className="fz-14">
              © 2024 Universitas Indonesia Orienteering Race. All right reserved
            </p>
          </div>
        </div>
      </div>
      <div className="circle-blur">
        <img src="/assets/imgs/patterns/blur1.png" alt="" />
      </div>
    </footer>
  );
}

export default Footer;
