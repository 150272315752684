/* eslint-disable jsx-a11y/anchor-is-valid */
'use client';
import React, { useEffect } from 'react';

function Navbar() {
  function handleScroll() {
    const bodyScroll = window.scrollY;
    const navbar = document.querySelector('.navbar');

    if (bodyScroll > 300) navbar.classList.add('nav-scroll');
    else navbar.classList.remove('nav-scroll');
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  function handleDropdownMouseMove(event) {
    event.currentTarget.querySelector('.dropdown-menu').classList.add('show');
  }

  function handleDropdownMouseLeave(event) {
    event.currentTarget
      .querySelector('.dropdown-menu')
      .classList.remove('show');
  }
  function handleToggleNav() {
    if (
      document
        .querySelector('.navbar .navbar-collapse')
        .classList.contains('show')
    ) {
      document
        .querySelector('.navbar .navbar-collapse')
        .classList.remove('show');
    } else if (
      !document
        .querySelector('.navbar .navbar-collapse')
        .classList.contains('show')
    ) {
      document.querySelector('.navbar .navbar-collapse').classList.add('show');
    }
  }
  const handleClickRegister = (e) => {
    e.preventDefault();
    window.open('https://bit.ly/uior2024', '_blank', 'noopener,noreferrer');
  };
  const handleClickBulletin1 = (e) => {
    e.preventDefault();
    window.open('https://drive.google.com/drive/folders/1bSCW96JqcHrCcw9_PRWzwVMqeORty9_3?usp=sharing', '_blank', 'noopener,noreferrer');
  };
  return (
    <nav className="navbar navbar-expand-lg bord blur">
      <div className="container o-hidden">
        <a className="logo icon-img-70" href="#">
          <img src="/assets/imgs/UIOR-White.png" alt="logo" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleToggleNav}
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
            <a className="nav-link" href="/">
                <span className="rolling-text">Home</span>
              </a>
            </li>
            <li
              onMouseLeave={handleDropdownMouseLeave}
              onMouseMove={handleDropdownMouseMove}
              className="nav-item dropdown"
            >
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="rolling-text">UIOR</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/welcome">
                    Welcome
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/about">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/organizer">
                    Organizer
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/entry-fee">
                    Entry Fee
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/venue">
                    Venue & Location
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/transportation">
                    Transportations & Accomodations
                  </a>
                </li>
              </ul>
            </li>
            <li
              onMouseLeave={handleDropdownMouseLeave}
              onMouseMove={handleDropdownMouseMove}
              className="nav-item dropdown"
            >
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="rolling-text">Competition</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/program">
                    Program
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/classes">
                    Classes
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/embargo">
                    Embargo Area
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/maps">
                    Maps
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/training">
                  Training Opportunities
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/information">
                    More Information
                  </a>
                </li>
              </ul>
            </li>
            <li
              onMouseLeave={handleDropdownMouseLeave}
              onMouseMove={handleDropdownMouseMove}
              className="nav-item dropdown"
            >
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="rolling-text">Bulletin</span>
              </a>
              <div className="dropdown-menu">
                <a 
                  className="dropdown-item"
                  href="https://drive.google.com/drive/folders/1bSCW96JqcHrCcw9_PRWzwVMqeORty9_3?usp=sharing"
                  onClick={handleClickBulletin1}
                >
                  Bulletin 1
                </a>
                <a className="dropdown-item" href="#">
                  Bulletin 2
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                <span className="rolling-text">Result</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">
                <span className="rolling-text">Contact Us</span>
              </a>
            </li>
          </ul>
        </div>

        <div className="contact-button">
          <a
            href="https://bit.ly/uior2024"
            onClick={handleClickRegister}
            className="butn butn-sm butn-bg main-colorbg radius-5"
          >
            <span className="text">REGISTER</span>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
