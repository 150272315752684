/* eslint-disable jsx-a11y/iframe-has-title */
'use client';
import React from 'react';

function Information() {
  const marquess = ['More Information']; // Replace with your actual array
  const AllMarquess = Array(10).fill(marquess).flat();
  const handleClickImigrasi = (e) => {
    e.preventDefault();
    window.open('https://www.imigrasi.go.id', '_blank', 'noopener,noreferrer');
  };
  return (
    <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
      <div className="ml-50 mr-50 mb-50 mt-50">
        <div className="flex justify-content-start">
          <div className="text">
            <h3 className="mb-50 col">
              More{' '}<span className="main-color">Information</span>
            </h3>
          </div>
        </div>
        <div className="row xlg-marg mb-50">
          <div className="col-lg-7">
            <div>
              <div className="sec-head mb-50">
                <h6 className="mb-5">PUNCHING AND TIMING</h6>
                <p>
                  The timing system will use SPORTident. SPORTident Air+ punching (SIAC) will be used for WRE & SPRINT RELAY and SPORTident Classic for other classes. Competitors who already have a dibber can inform their SICard ID to the organizers. 
                </p>
                <br/>
                <h6 className="mb-5">CLOTHING AND FOOTWEAR</h6>
                <p>
                  There are no specific regulations regarding clothing and footwear. Competitors are encouraged to wear comfortable clothing that allows them for easy movements. Please use comfortable shoes that facilitate movement, considering that 60% of the course consists of hard surfaces; asphalt and paving blocks. 
                </p>
                <br/>
                <h6 className="mb-5">REFRESHMENT POINTS</h6>
                <p>
                  There are no specific regulations regarding clothing and footwear. Competitors are encouraged to wear comfortable clothing that allows them for easy movements. Please use comfortable shoes that facilitate movement, considering that 60% of the course consists of hard surfaces; asphalt and paving blocks. 
                </p>
                <br/>
                <h6 className="mb-5">INSURANCE</h6>
                <p>
                  Each competitors is expected to have their own insurance/BPJS when participating in this event. Competitors are responsible for their own insurance coverage (for illness and accidents). 
                </p>
                <br/>
                <h6 className="mb-5">VISA REQUIREMENTS</h6>
                  <p>
                  For information about visa requirements to visit Indonesia, please check the website:  
                  <br/>
                  <a href="https://www.imigrasi.go.id" onClick={handleClickImigrasi}>
                    https://www.imigrasi.go.id
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="img-100 fit-img">
              <img src="/assets/imgs/UI-Information.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="marq-head mb-50">
        <div className="main-marq xlrg text-u o-hidden">
          <div className="slide-har st1">
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Information;
