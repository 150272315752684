/* eslint-disable jsx-a11y/iframe-has-title */
'use client';
import React from 'react';

function Program() {
  const marquess = ['Event Program', 'Supporting Events']; // Replace with your actual array
  const AllMarquess = Array(10).fill(marquess).flat();
  return (
    <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
      <div className="ml-50 mr-50 mb-50 mt-50">
        <div className="flex justify-content-start">
          <div className="text">
            <h3 className="mb-40 col">
              Event{' '}<span className="main-color">Program</span>
            </h3>
          </div>
        </div>
        <div className="info mb-40 pb-20 bord-thin-bottom">
          <div className="row">
            <div className="col-md-8 col-lg-4">
              <div className="item mb-30">
                <span className="opacity-8 mb-5">Date :</span>
                  <h6>Fri 25th October, 2024</h6>
              </div>
            </div>
            <div className="col-md-8 col-lg-4">
              <div className="item mb-30">
                <span className="opacity-8 mb-15">Activity :</span>
                  <div>
                    <ul className="rest dot-list">
                      <li className="mb-5">Arrival of Participants</li>
                      <li className="mb-5 main-color"><b>Registration (Check-in)</b></li>
                      <li className="mb-5">Race Pack Collection</li>
                      <li className="mb-5 main-color"><b>Opening Ceremony</b></li>
                      <li>Model Event</li>
                    </ul>
                  </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-4">
              <div className="item mb-30">
                <span className="opacity-8 mb-5">Place :</span>
                <h6>Event Center <br/> Balairung Universitas Indonesia</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="info mb-40 pb-20 bord-thin-bottom">
          <div className="row">
            <div className="col-md-8 col-lg-4">
              <div className="item mb-30">
                <span className="opacity-8 mb-5">Date :</span>
                  <h6>Sat 26th October, 2024</h6>
              </div>
            </div>
            <div className="col-md-8 col-lg-4">
              <div className="item mb-30">
                <span className="opacity-8 mb-15">Activity :</span>
                  <div>
                    <ul className="rest dot-list">
                      <li className="mb-5 main-color"><b>Sprint (WRE)</b></li>
                      <li className="mb-5">Price-Giving Ceremony Sprint</li>
                      <li className="mb-5 main-color"><b>Sprint Relay</b></li>
                      <li className="mb-5">Price-Giving Ceremony Sprint Relay</li>
                      <li className="main-color"><b>Closing Ceremony</b></li>
                    </ul>
                  </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-4">
              <div className="item mb-30">
                <span className="opacity-8 mb-5">Place :</span>
                <h6>Universitas Indonesia</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="info mb-40 pb-20 bord-thin-bottom">
          <div className="row">
            <div className="col-md-8 col-lg-4">
              <div className="item mb-30">
                <h3 className="mb-40 col">
                  Supporting{' '}<span className="main-color">Events</span>
                </h3>
              </div>
            </div>
            <div className="col-md-8 col-lg-4">
              <div className="item mb-30">
                <h6 className="mb-10">SME Bazaar</h6>
                <div className="img-100 fit-img">
                  <img src="/assets/imgs/UI-Bazzar.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-4">
              <div className="item mb-30">
                <h6 className="mb-10">Traditional Dance and Live Music</h6>
                <div className="img-100 fit-img">
                  <img src="/assets/imgs/UI-Dance.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marq-head mb-50">
        <div className="main-marq xlrg text-u o-hidden">
          <div className="slide-har st1">
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
            <div className="box">
              {AllMarquess.map((item, i) => (
                <div key={i} className="item">
                  <h4>{item}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Program;
