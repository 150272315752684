import React from "react";

function Welcome() {
    const marquess = ['Welcome to University Indonesia']; // Replace with your actual array
    const AllMarquess = Array(10).fill(marquess).flat();
    return (
      <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
        <div className="ml-50 mr-50 mb-50 mt-50">
          <div className="text">
            <h3 className="mb-50">
              Welcome to <span className="main-color">University of Indonesia</span>
            </h3>
          </div>
          <div className="row lg-marg">
            <div className="col-lg-7 md-mb80">
              <div className="row lg-marg align-items-start">
                <div className="col-md-6 mb-50 bord-bottom-grd">
                  <div className="text">
                    <p>
                      With immense gratitude, we welcome you to Universitas Indonesia for the Universitas Indonesia Orienteering Race: World Ranking Event. As the Event Director, I extend my deepest gratitude to everyone who was involved in and contributed to the success of UIOR 2024.
                    </p>
                    <br></br>
                    <p>
                      We are committed to creating an unforgettable experience through exciting competitions in all categories, all set against the stunning urban environment of Universitas Indonesia. We hope you enjoy every moment of UIOR.
                    </p>
                    <br></br>
                    <p>
                      Welcome to Universitas Indonesia! Enjoy every moment and happy competing.
                    </p>
                    <p className="main-color mt-40">
                      <span className="text">
                        <b>Event Director</b>
                      </span>
                      <br>
                      </br>
                      <span>Siti Fadilah Apriani</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 bord-bottom-grd">
                  <div className="text">
                    <p>
                      Orienteering teaches us about physical strength, tactical thinking, and how to wisely decide the best strategies to achieve victory. Universitas Indonesia boasts some of the best terrain in the country. Through the Universitas Indonesia Orienteering Race 2024, let's witness a spectacular urban navigation journey.
                    </p>
                    <br></br>
                    <p>
                      Warm greetings to all athletes, participants, and spectators of the UIOR 2024! We look forward to seeing you here and wish you the best!
                    </p>
  
                    <p className="main-color mt-40">
                      <span className="text">
                        <b>Chief of Mapala UI</b>
                      </span>
                      <br>
                      </br>
                      <span>Muhammad Fikra Alfath F.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="img-full fit-img">
                <img src="/assets/imgs/UI-Rektorat.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="marq-head mb-50">
          <div className="main-marq xlrg text-u o-hidden">
            <div className="slide-har st1">
              <div className="box">
                {AllMarquess.map((item, i) => (
                  <div key={i} className="item">
                    <h4>{item}</h4>
                  </div>
                ))}
              </div>
              <div className="box">
                {AllMarquess.map((item, i) => (
                  <div key={i} className="item">
                    <h4>{item}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Welcome;
