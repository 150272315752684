import React from "react";

function Welcome() {
    const marquess = ['Entry Fee UIOR']; // Replace with your actual array
    const AllMarquess = Array(10).fill(marquess).flat();
    const handleClickRegister = (e) => {
      e.preventDefault();
      window.open('https://bit.ly/uior2024', '_blank', 'noopener,noreferrer');
    };
    return (
      <section className="intro section-padding-top sub-bg bord-top-grd bord-bottom-grd">
        <div className="ml-50 mr-50 mb-50 mt-50">
          <div className="flex jc-space-between">
            <div className="text">
            <div className="contact-button">
              <a
                href="https://bit.ly/uior2024"
                className="butn butn-sm butn-bg main-colorbg radius-5"
                onClick={handleClickRegister}
              >
                <span className="text">REGISTER</span>
              </a>
            </div>
            </div>
            <div className="text">
              <h3 className="mb-50 col">
                Entry Fee <span className="main-color">UIOR</span>
              </h3>
            </div>
          </div>
          <div className="row lg-marg">
            <div className="col-lg-8 md-mb80">
              <div className="item d-flex align-items-center mb-30">
                <span className="num">01</span>
                <div>
                  <span className="sub-title-fee main-color mb-5 fw-700">Sprint</span>
                  <h2>
                    W18 / M18
                  </h2>
                </div>
                <div className="ml-auto">
                  <a href="https://bit.ly/uior2024" onClick={handleClickRegister}>
                    LOCALS - Rp. 250.000
                    <br/>
                    FOREIGNERS - Rp. 500.000
                  </a>
                </div>
              </div>
              <div className="item d-flex align-items-center mb-30">
                <span className="num">02</span>
                <div>
                  <span className="sub-title-fee main-color mb-5 fw-700">Sprint</span>
                  <h2>
                    W20 / M20
                  </h2>
                </div>
                <div>
                </div>
                <div className="ml-auto">
                  <a href="https://bit.ly/uior2024" onClick={handleClickRegister}>
                    LOCALS - Rp. 250.000
                    <br/>
                    FOREIGNERS - Rp. 500.000
                  </a>
                </div>
              </div>
              <div className="item d-flex align-items-center mb-30">
                <span className="num">03</span>
                <div>
                  <span className="sub-title-fee main-color mb-5 fw-700">Sprint</span>
                  <h2>
                    W21 / M21 <span className="fw-200 sub-title-info">{'(WRE)'}</span>
                  </h2>
                </div>
                <div className="ml-auto">
                  <a href="https://bit.ly/uior2024" onClick={handleClickRegister}>
                    LOCALS - Rp. 350.000
                    <br/>
                    FOREIGNERS - Rp. 650.000
                  </a>
                </div>
              </div>
              <div className="item d-flex align-items-center mb-30">
                <span className="num">04</span>
                <div>
                  <span className="sub-title-fee main-color mb-5 fw-700">Sprint</span>
                  <h2>
                    W35 / M35
                  </h2>
                </div>
                <div className="ml-auto">
                  <a href="https://bit.ly/uior2024" onClick={handleClickRegister}>
                    LOCALS - Rp. 250.000
                    <br/>
                    FOREIGNERS - Rp. 500.000
                  </a>
                </div>
              </div>
              <div className="item d-flex align-items-center mb-30">
                <span className="num">05</span>
                <div>
                  <h2 className="main-color">
                    Sprint Relay <br/> <span className="main-white fw-200 sub-title-info">{'(2 Male & 2 Female)'}</span>
                  </h2>
                </div>
                <div className="ml-auto">
                  <a href="https://bit.ly/uior2024" onClick={handleClickRegister}>
                    LOCALS - Rp. 450.000
                    <br/>
                    FOREIGNERS - Rp. 600.000
                    <br/>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-full fit-img">
                <img src="/assets/imgs/UI-EntryFee.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="marq-head mb-50">
          <div className="main-marq xlrg text-u o-hidden">
            <div className="slide-har st1">
              <div className="box">
                {AllMarquess.map((item, i) => (
                  <div key={i} className="item">
                    <h4>{item}</h4>
                  </div>
                ))}
              </div>
              <div className="box">
                {AllMarquess.map((item, i) => (
                  <div key={i} className="item">
                    <h4>{item}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Welcome;
